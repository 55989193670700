// Anpassungen für den Internet Explorer 9
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


@import "variables";



// ***** Aufmacher *************************************************************

.nav-main{
  display: table;
}

ul.nav-main-items{
  display: table-row;
}
ul.nav-main-items li{
  display: table-cell;
}
